export const NAVBAR_ANIMATION_DURATION = 0.3

export const getAnimations = (shouldAnimate: boolean) => ({
	expandedLogo: {
		variants: {
			visible: {
				display: "block",
				opacity: 1,
				x: 0,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
			hidden: {
				display: "none",
				opacity: 0,
				x: 25,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
		},
		initial: false,
	},
	collapsedLogo: {
		variants: {
			visible: {
				display: "block",
				opacity: 1,
				x: 0,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
			hidden: {
				display: "none",
				opacity: 0,
				x: -25,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
		},
		initial: false,
	},
	expandedContainer: {
		variants: {
			visible: {
				display: "flex",
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							staggerChildren: 0.05,
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
			hidden: {
				display: "none",
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
		},
		initial: false,
	},
	collapsedContainer: {
		variants: {
			visible: {
				display: "flex",
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							staggerChildren: 0.02,
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
			hidden: {
				display: "none",
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: NAVBAR_ANIMATION_DURATION,
						},
			},
		},
		initial: false,
	},
	expandedGroup: {
		variants: {
			visible: {
				opacity: 1,
				x: 0,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							staggerChildren: 0.05,
							type: "spring",
							stiffness: 35,
							damping: 2.2,
							mass: 0.1,
						},
			},
			hidden: {
				opacity: 0,
				x: 40,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: 0.25,
						},
			},
		},
		initial: false,
	},
	collapsedSeparator: {
		variants: {
			visible: {
				opacity: 1,
				x: 0,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							delay: 0.3,
							type: "spring",
							stiffness: 30,
							damping: 1.8,
							mass: 0.1,
						},
			},
			hidden: {
				opacity: 0,
				x: -15,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: 0.3,
						},
			},
		},
		initial: false,
	},
	expandedItem: {
		variants: {
			visible: {
				opacity: 1,
				x: 0,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							type: "spring",
							stiffness: 30,
							damping: 1.8,
							mass: 0.1,
						},
			},
			hidden: {
				opacity: 0,
				x: 25,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: 0.3,
						},
			},
		},
		initial: false,
	},
	collapsedItem: {
		variants: {
			visible: {
				opacity: 1,
				x: 0,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							type: "spring",
							stiffness: 30,
							damping: 1.8,
							mass: 0.1,
						},
			},
			hidden: {
				opacity: 0,
				x: -25,
				transition:
					shouldAnimate ?
						{ duration: 0 }
					:	{
							duration: 0.3,
						},
			},
		},
		initial: false,
	},
})
