import { Outlet } from "@remix-run/react"

import {
	AppNavbar,
	NavbarInset,
	NavbarProvider,
} from "@/components/global/navbar"

export const description = "A sidebar that collapses to icons."

export default function Page() {
	return (
		<div className="absolute inset-0 flex flex-col overflow-hidden lg:flex-row">
			<NavbarProvider>
				<AppNavbar />
				<NavbarInset>
					<Outlet />
				</NavbarInset>
			</NavbarProvider>
		</div>
	)
}
