/* eslint-disable react/display-name */
import type { CSSProperties, HTMLAttributes } from "react"
import {
	createContext,
	forwardRef,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { Link, useLocation } from "@remix-run/react"
import { motion, useReducedMotion } from "framer-motion"
import { ChevronRightIcon, MenuIcon } from "lucide-react"

import type { PageConfig, PageGroup, PageId } from "@/config/pages"
import { pagesConfig } from "@/config/pages"
import { useIsMobile } from "@/lib/hooks/use-is-mobile"
import { cn } from "@/lib/utils/classnames"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible"
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetTitle,
	SheetTrigger,
} from "@/components/ui/sheet"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { typographyVariants } from "@/components/ui/typography"
import { VisuallyHidden } from "@/components/ui/visually-hidden"
import { useDetailsPages } from "@/components/shared/details/use-details-state"
import {
	Logo as SVGLogo,
	LogoSmall as SVGLogoSmall,
} from "@/components/shared/logo"

import { getAnimations, NAVBAR_ANIMATION_DURATION } from "./animations"

const NAVBAR_DIMENSIONS = {
	EXPANDED: "16rem",
	MOBILE: "18rem",
	COLLAPSED: "5rem",
} as const

const NAVBAR_KEYBOARD_SHORTCUT = "b"

// Types
interface NavbarContext {
	state: "expanded" | "collapsed"
	isExpanded: boolean
	setIsExpanded: (open: boolean) => void
	openMobile: boolean
	setOpenMobile: (open: boolean) => void
	isMobile: boolean
	toggleNavbar: () => void
	animations: ReturnType<typeof getAnimations>
}

// Context & Hook
const NavbarContext = createContext<NavbarContext | null>(null)

const useNavbar = () => {
	const context = useContext(NavbarContext)
	if (!context) {
		throw new Error("useNavbar must be used within a NavbarProvider")
	}
	return context
}

// Provider Component
export const NavbarProvider = ({ children }: { children: React.ReactNode }) => {
	const isMobile = useIsMobile()
	const [isExpanded, setIsExpanded] = useState(true)
	const [openMobile, setOpenMobile] = useState(false)
	const prefersReducedMotion = useReducedMotion()

	const toggleNavbar = useCallback(() => {
		return isMobile ?
				setOpenMobile((open) => !open)
			:	setIsExpanded((open) => !open)
	}, [isMobile])

	// Reset mobile navigation state on desktop
	useEffect(() => {
		if (isMobile) return
		setOpenMobile(false)
	}, [isMobile])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (
				event.key === NAVBAR_KEYBOARD_SHORTCUT &&
				(event.metaKey || event.ctrlKey)
			) {
				event.preventDefault()
				toggleNavbar()
			}
		}

		window.addEventListener("keydown", handleKeyDown)
		return () => window.removeEventListener("keydown", handleKeyDown)
	}, [toggleNavbar])

	const contextValue: NavbarContext = useMemo(
		() => ({
			state: isExpanded ? "expanded" : "collapsed",
			isExpanded,
			setIsExpanded,
			isMobile,
			openMobile,
			setOpenMobile,
			toggleNavbar,
			animations: getAnimations(!!prefersReducedMotion),
		}),
		[isExpanded, isMobile, openMobile, toggleNavbar, prefersReducedMotion],
	)

	return (
		<NavbarContext.Provider value={contextValue}>
			<TooltipProvider delayDuration={0}>{children}</TooltipProvider>
		</NavbarContext.Provider>
	)
}

// Base Components
const DesktopNavbarContainer = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement>
>(({ className, style, ...props }, ref) => {
	const { state, isExpanded } = useNavbar()
	const navbarWidth =
		isExpanded ? NAVBAR_DIMENSIONS.EXPANDED : NAVBAR_DIMENSIONS.COLLAPSED

	return (
		<div
			ref={ref}
			data-state={state}
			style={
				{
					"--navbar-width": navbarWidth,
					"transitionDuration": `${NAVBAR_ANIMATION_DURATION}s`,
					...style,
				} as CSSProperties
			}
			className={cn(
				"flex w-[--navbar-width] flex-col overflow-hidden p-4 transition-[width] ease-out",
				className,
			)}
			{...props}
		/>
	)
})

export const NavbarInset = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<main
		ref={ref}
		className={cn(
			"relative flex flex-1 gap-1 overflow-hidden md:rounded-xl md:px-1 md:pb-1 lg:gap-2 lg:p-2 lg:pl-0",
			className,
		)}
		{...props}
	/>
))
NavbarInset.displayName = "NavbarInset"

const NavbarContent = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("relative flex-1 overflow-hidden", className)}
		{...props}
	/>
))

// Logo Components
const ExpandedLogo = ({ className }: { className?: string }) => {
	const { animations } = useNavbar()
	return (
		<motion.div
			{...animations.expandedLogo}
			className={cn("mx-auto shrink-0", className)}
		>
			<SVGLogo className={cn("w-full max-w-[200px] dark:hidden")} />
			<SVGLogo
				color="white"
				className={cn("hidden w-full max-w-[200px] dark:block")}
			/>
		</motion.div>
	)
}

const CollapsedLogo = ({ className }: { className?: string }) => {
	const { animations } = useNavbar()
	return (
		<motion.div
			{...animations.collapsedLogo}
			className={cn("mx-auto shrink-0", className)}
		>
			<SVGLogoSmall className={cn("w-full max-w-10 dark:hidden")} />
			<SVGLogoSmall
				color="white"
				className={cn("hidden w-full max-w-10 dark:block")}
			/>
		</motion.div>
	)
}

// Navigation Item Components
const useNavItemState = (path: string, id: PageId) => {
	const pagesState = useDetailsPages()
	const location = useLocation()
	const isActive = location.pathname === path

	const pathWithState = useMemo(() => {
		const pageState = pagesState[id]?.singleState
		if (!pageState) return path

		const params = new URLSearchParams()
		Object.entries(pageState).forEach(([key, value]) => {
			params.append(key, value)
		})

		return `${path}?${params.toString()}`
	}, [path, pagesState, id])

	return { isActive, pathWithState }
}

const NavbarExpandedItem = ({ page }: { page: PageConfig }) => {
	const { path, id, icon: Icon, label } = page

	const { isActive, pathWithState } = useNavItemState(path, id)
	const { animations } = useNavbar()

	return (
		<motion.div {...animations.expandedItem}>
			<Link
				to={pathWithState}
				className={cn(
					"group ml-5 flex items-center space-x-2 rounded px-3 py-1.5 text-foreground/70 transition-colors",
					isActive ?
						"bg-primary font-medium text-primary-foreground"
					:	"hover:bg-hover hover:text-foreground-strong",
				)}
			>
				<Icon
					className={cn(
						"size-4",
						isActive ?
							"text-primary-foreground"
						:	"text-primary-text opacity-70 group-hover:text-foreground-strong group-hover:opacity-100 dark:text-foreground",
					)}
				/>
				<span>{label}</span>
			</Link>
		</motion.div>
	)
}

const NavbarCollapsedItem = ({ page }: { page: PageConfig }) => {
	const { path, id, icon: Icon, label } = page
	const { isActive, pathWithState } = useNavItemState(path, id)

	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<Link
					to={pathWithState}
					className={cn(
						"inline-flex size-9 shrink-0 items-center justify-center rounded-md outline outline-0 outline-offset-1 outline-focus transition-colors focus-visible:outline-2",
						isActive ?
							"bg-primary text-primary-foreground"
						:	"hover:bg-hover/[8%] hover:text-foreground-strong",
					)}
				>
					<Icon className="size-5" />
				</Link>
			</TooltipTrigger>
			<TooltipContent side="right" sideOffset={8}>
				{label}
			</TooltipContent>
		</Tooltip>
	)
}

// Navigation Group Components
const NavbarExpandedGroup = ({ group }: { group: PageGroup }) => {
	const location = useLocation()
	const isActive = useMemo(
		() => group.pages.some((item) => item.path === location.pathname),
		[group.pages, location.pathname],
	)
	const { animations } = useNavbar()

	return (
		<motion.div {...animations.expandedGroup}>
			<Collapsible defaultOpen={isActive}>
				<CollapsibleTrigger
					className={cn(
						typographyVariants({ variant: "overline" }),
						"group flex w-full items-center space-x-2 rounded p-2 transition-colors hover:bg-hover",
					)}
				>
					<ChevronRightIcon className="size-4 shrink-0 -translate-y-px transition-transform group-data-[state=open]:-translate-y-0 group-data-[state=open]:rotate-90" />
					<span className="font-medium">{group.label}</span>
				</CollapsibleTrigger>
				<CollapsibleContent className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
					<div className="space-y-1 py-1">
						{group.pages.map((item) => (
							<NavbarExpandedItem key={item.id} page={item} />
						))}
					</div>
				</CollapsibleContent>
			</Collapsible>
		</motion.div>
	)
}

const NavbarCollapsedGroup = ({
	group,
	hasSeparator,
}: {
	group: PageGroup
	hasSeparator: boolean
}) => {
	const { state, animations } = useNavbar()

	return (
		<>
			{hasSeparator && (
				<motion.div
					{...animations.collapsedSeparator}
					animate={state === "collapsed" ? "visible" : "hidden"}
					className="h-px w-full bg-foreground/[15%]"
				/>
			)}
			{group.pages.map((item) => (
				<motion.div key={item.id} {...animations.collapsedItem}>
					<NavbarCollapsedItem page={item} />
				</motion.div>
			))}
		</>
	)
}

// Container Components
const NavbarExpandedContainer = ({ className }: { className?: string }) => {
	const { state, animations, isMobile } = useNavbar()

	return (
		<motion.div
			{...animations.expandedContainer}
			animate={state === "expanded" || isMobile ? "visible" : "hidden"}
			aria-hidden={state !== "expanded" && !isMobile}
			className={cn(
				"absolute inset-y-0 flex flex-col space-y-6 overflow-x-hidden py-4",
				className,
			)}
		>
			<ExpandedLogo />
			<nav className="space-y-1">
				{pagesConfig.map((group) => (
					<NavbarExpandedGroup key={group.id} group={group} />
				))}
			</nav>
		</motion.div>
	)
}

const NavbarCollapsedContainer = ({ className }: { className?: string }) => {
	const { state, animations } = useNavbar()

	return (
		<motion.div
			{...animations.collapsedContainer}
			animate={state === "collapsed" ? "visible" : "hidden"}
			aria-hidden={state !== "collapsed"}
			className={cn(
				"absolute inset-y-0 flex flex-col items-center space-y-4 overflow-x-auto",
				className,
			)}
		>
			<CollapsedLogo />
			<nav className="space-y-1">
				{pagesConfig.map((group, i) => (
					<NavbarCollapsedGroup
						key={group.id}
						group={group}
						hasSeparator={i > 0}
					/>
				))}
			</nav>
		</motion.div>
	)
}

// Footer Component
const NavbarFooter = ({ ...props }) => {
	const { toggleNavbar, isExpanded } = useNavbar()

	return (
		<div className="border-t border-foreground/[15%] pt-3">
			<motion.div
				className={cn(
					"relative transition-[height]",
					isExpanded ? "h-11" : "h-24",
				)}
				{...props}
			>
				<Button
					variant="ghost"
					size="none"
					onClick={toggleNavbar}
					className={cn(
						"absolute top-0 transition-all",
						isExpanded ? "left-0 h-11 w-8" : (
							"left-1/2 size-11 -translate-x-1/2"
						),
					)}
				>
					<ChevronRightIcon
						className={cn(
							"size-4 shrink-0",
							isExpanded && "rotate-180",
						)}
					/>
				</Button>
				<Button
					variant="ghost"
					size="none"
					className={cn(
						"absolute bottom-0 flex flex-1 items-center gap-2 text-left transition-all",
						isExpanded ?
							"right-0 justify-start"
						:	"right-1/2 translate-x-1/2 justify-center",
						isExpanded ?
							"w-[calc(100%-theme(width.9))]"
						:	"size-11 gap-0",
					)}
				>
					<Avatar className="scale-90">
						<AvatarFallback>Mike Mechanic</AvatarFallback>
					</Avatar>
					<div
						className={cn(
							"-space-y-0.5 leading-none transition-[opacity,width]",
							isExpanded ? "w-full opacity-100" : "w-0 opacity-0",
						)}
					>
						<h4 className="truncate text-base font-semibold">
							Mike Mechanic
						</h4>
						<p className="truncate text-sm">WP Tampa West</p>
					</div>
				</Button>
			</motion.div>
		</div>
	)
}

// Main App Component
export const AppNavbar = () => {
	const { openMobile, setOpenMobile } = useNavbar()

	return (
		<>
			<div className="lg:hidden">
				<div className="grid h-14 grid-cols-[auto_1fr_auto] items-center gap-4 px-4">
					<Sheet
						open={openMobile}
						onOpenChange={setOpenMobile}
						direction="left"
					>
						<SheetTrigger asChild>
							<Button variant="ghost" size="icon">
								<MenuIcon className="size-5" />
							</Button>
						</SheetTrigger>
						<SheetContent
							style={
								{
									"--navbar-width": NAVBAR_DIMENSIONS.MOBILE,
								} as React.CSSProperties
							}
							className="*:relative *:w-[--navbar-width] *:p-4"
						>
							<VisuallyHidden>
								<SheetTitle>Navigation Menu</SheetTitle>
								<SheetDescription>
									Main navigation menu providing access to all
									key areas of the application. Use this menu
									to navigate between different sections and
									features.
								</SheetDescription>
							</VisuallyHidden>
							<NavbarExpandedContainer className="w-[calc(var(--navbar-width)-theme(spacing.8))] py-8" />
						</SheetContent>
					</Sheet>

					<div className="flex justify-center">
						<SVGLogo className="w-full max-w-[200px] dark:hidden" />
						<SVGLogo
							color="white"
							className="hidden w-full max-w-[200px] dark:block"
						/>
					</div>

					<Button variant="ghost" size="icon">
						<Avatar className="scale-75">
							<AvatarFallback>Mike Mechanic</AvatarFallback>
						</Avatar>
					</Button>
				</div>
			</div>

			<DesktopNavbarContainer
				style={
					{
						"--expanded-width": NAVBAR_DIMENSIONS.EXPANDED,
						"--collapsed-width": NAVBAR_DIMENSIONS.COLLAPSED,
					} as CSSProperties
				}
				className="max-lg:hidden"
			>
				<NavbarContent>
					<NavbarExpandedContainer className="w-[calc(var(--expanded-width)-theme(spacing.8))]" />
					<NavbarCollapsedContainer className="w-[calc(var(--collapsed-width)-theme(spacing.8))]" />
				</NavbarContent>
				<NavbarFooter />
			</DesktopNavbarContainer>
		</>
	)
}
