import * as React from "react"
import { tv } from "tailwind-variants"
import { Drawer as SheetPrimitive } from "vaul"

import { cn } from "@/lib/utils/classnames"

const SheetDirectionContext = React.createContext<
	React.ComponentProps<typeof Sheet>["direction"] | null
>(null)
const Sheet = ({
	shouldScaleBackground = true,
	direction = "bottom",
	children,
	...props
}: React.ComponentProps<typeof SheetPrimitive.Root>) => (
	<SheetPrimitive.Root
		shouldScaleBackground={shouldScaleBackground}
		direction={direction}
		{...props}
	>
		<SheetDirectionContext.Provider value={direction}>
			{children}
		</SheetDirectionContext.Provider>
	</SheetPrimitive.Root>
)
Sheet.displayName = "Sheet"

const SheetTrigger = SheetPrimitive.Trigger

const SheetPortal = SheetPrimitive.Portal

const SheetClose = SheetPrimitive.Close

const SheetOverlay = React.forwardRef<
	React.ElementRef<typeof SheetPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<SheetPrimitive.Overlay
		ref={ref}
		className={cn("fixed inset-0 z-50 bg-black/[10%]", className)}
		{...props}
	/>
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetContentVariants = tv({
	base: "fixed z-50 flex",
	variants: {
		direction: {
			top: "inset-x-0 top-0 mb-24 h-auto w-full",
			bottom: "inset-x-0 bottom-0 mt-24 h-auto w-full",
			left: "inset-y-0 left-0 h-full w-auto sm:max-w-sm",
			right: "inset-y-0 right-0 h-full w-auto sm:max-w-sm",
		},
	},
	defaultVariants: {
		direction: "bottom",
	},
})

const SheetContent = React.forwardRef<
	React.ElementRef<typeof SheetPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>
>(({ className, children, ...props }, ref) => {
	const direction = React.useContext(SheetDirectionContext) ?? "bottom"
	return (
		<SheetPortal>
			<SheetOverlay />
			<SheetPrimitive.Content
				ref={ref}
				className={cn(sheetContentVariants({ direction }), className)}
				{...props}
			>
				<div className="m-2 grow rounded-xl bg-background p-6 shadow-lg">
					{children}
				</div>
			</SheetPrimitive.Content>
		</SheetPortal>
	)
})
SheetContent.displayName = "SheetContent"

const SheetHeader = ({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)}
		{...props}
	/>
)
SheetHeader.displayName = "SheetHeader"

const SheetFooter = ({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cn("mt-auto flex flex-col gap-2 p-4", className)}
		{...props}
	/>
)
SheetFooter.displayName = "SheetFooter"

const SheetTitle = React.forwardRef<
	React.ElementRef<typeof SheetPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
	<SheetPrimitive.Title
		ref={ref}
		className={cn(
			"text-lg font-semibold leading-none tracking-tight",
			className,
		)}
		{...props}
	/>
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = React.forwardRef<
	React.ElementRef<typeof SheetPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
	<SheetPrimitive.Description
		ref={ref}
		className={cn("text-sm text-foreground-weak", className)}
		{...props}
	/>
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

export {
	Sheet,
	SheetPortal,
	SheetOverlay,
	SheetTrigger,
	SheetClose,
	SheetContent,
	SheetHeader,
	SheetFooter,
	SheetTitle,
	SheetDescription,
}
